import React from 'react'
import { Link } from 'gatsby'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { ChevronRight } from 'react-feather'
import {
	ContentSection,
	ContentSidebarContainer,
	ContentBg,
	BlockQuote,
	ImageAsset,
	Bold,
	Italic,
	UnderlinedText,
	CodeText,
	Text,
	UnorderedList,
	OrderedList,
	Heading1,
	Heading2,
	Heading3,
	Heading4,
	Heading5,
	Heading6,
	HyperLinkText,
	NoContent,
	CrumbsContainer,
	FaqTitle,
} from './styles'

const Content = ({ checkedLocale, content, title }) => {
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
      [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
      [MARKS.UNDERLINE]: text => <UnderlinedText>{text}</UnderlinedText>,
      [MARKS.CODE]: text => <CodeText>{text}</CodeText>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <Heading1>{children}</Heading1>,
      [BLOCKS.HEADING_2]: (node, children) => <Heading2>{children}</Heading2>,
      [BLOCKS.HEADING_3]: (node, children) => <Heading3>{children}</Heading3>,
      [BLOCKS.HEADING_4]: (node, children) => <Heading4>{children}</Heading4>,
      [BLOCKS.HEADING_5]: (node, children) => <Heading5>{children}</Heading5>,
      [BLOCKS.HEADING_6]: (node, children) => <Heading6>{children}</Heading6>,
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.UL_LIST]: (node, children) => (
        <UnorderedList>{children}</UnorderedList>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <OrderedList>{children}</OrderedList>
      ),
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { description, title, file } = node.data.target.fields
        return (
          <ImageAsset>
            <img src={file['en-AU'].url} alt={description['en-AU']} />
          </ImageAsset>
        )
      },
      [BLOCKS.QUOTE]: (node, children) => <BlockQuote>{children}</BlockQuote>,
      [INLINES.HYPERLINK]: (node, children) => {
        const text = node.content[0].value
        return <HyperLinkText>{text}</HyperLinkText>
      },
    },
  }

  return (
    <ContentSection>
      <ContentSidebarContainer>
          <CrumbsContainer>
            <Link to={`${checkedLocale}`}>Home</Link>
						<ChevronRight width="18px" height="18px" />
            <Link to={`${checkedLocale}/support/faq`}>Support</Link>
						<ChevronRight width="18px" height="18px" />
						<FaqTitle>{title}</FaqTitle>
          </CrumbsContainer>
          <ContentBg>
            {content ? documentToReactComponents(content.json, options) : <NoContent>No Content for this yet.</NoContent>}
          </ContentBg>
      </ContentSidebarContainer>
    </ContentSection>
  )
}

export default Content
