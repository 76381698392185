import tw, { css, styled } from 'twin.macro'

export const HeroSection = tw.section`flex flex-col bg-dark-grayish-blue w-full py-16 items-center`
export const Title = tw.h1`font-display text-2xl text-white font-bold my-2 px-6 text-center`

export const ContentSection = styled.section`
	${tw`py-12 md:py-20 w-full`}
	background-color: #eeeeee;
`
export const ContentSidebarContainer = tw.div`flex flex-col px-2 md:px-4 lg:w-11/12 xl:w-4/5 xxl:w-9/12 m-auto`
export const ContentBg = tw.div`flex flex-col bg-white py-4 px-2`

export const BlockQuote = styled.blockquote`
	padding: 1em;
	border-left: 5px solid #999;
	&:before {
		display: none;
	}
	&:not(:first-of-type) {
		margin-top: 0.5em;
	}
	p {
		color: #555;
		font-size: 12pt;
		line-height: 1.4;
		${tw`font-display`}
	}
	code {
		margin-top: 0.5em;
		padding: 0;
		color: #777;
		font-size: 12pt;
		text-align: left;
		font-style: italic;
		&:before {
			content: '— ';
		}
	}
	&:nth-of-type(even) {
		text-align: right;
		border-left: none;
		border-right: 5px solid #999;
	}
	&:nth-of-type(even) code {
		text-align: right;
	}
	&:nth-of-type(even) code:before {
		content: '';
	}
	&:nth-of-type(even) code:after {
		content: ' —';
	}
	@media screen and (min-width: 300px) {
		padding: 1em 20% 1em 1em;
		p {
			font-size: 14pt;
		}
		&:nth-of-type(even) {
			padding: 1em 1em 1em 20%;
		}
	}
`

// new styles

export const ImageAsset = tw.div`my-2`
export const Bold = tw.b`font-bold`
export const Italic = tw.i`italic`
export const UnderlinedText = tw.u`underline`
export const CodeText = tw.code``
export const Text = tw.p`font-display text-base mb-2 leading-snug tracking-wide px-4`
export const UnorderedList = styled.ul`
	${tw`mx-8 px-4 mb-2`}

	> li {
		${tw`list-disc`}
	}
	> li > p {
		${tw`text-sm pl-0`}
	}
`
export const OrderedList = styled.ol`
	${tw`mx-8 px-4 mb-2`}

	> li {
		${tw`list-decimal`}
	}
	> li > p {
		${tw`text-sm pl-0`}
	}
`
export const Heading1 = styled.h1`
	${tw`font-display font-bold text-4xl mb-6 text-blue-500 bg-gray-200 py-2 pl-6 pl-6 pr-2 border-l-4 border-blue-500 uppercase`}
`
export const Heading2 = styled.h2`
	${tw`font-display font-bold text-3xl mb-6 text-blue-500 bg-gray-200 py-2 pl-6 pl-6 pr-2 border-l-4 border-blue-500 uppercase mt-4`}
`
export const Heading3 = styled.h3`
	${tw`font-display font-bold text-2xl mb-6 text-blue-500 bg-gray-200 py-2 pl-6 pl-6 pr-2 border-l-4 border-blue-500 uppercase mt-4`}
`
export const Heading4 = styled.h4`
	${tw`font-display font-bold text-xl mb-6 text-blue-500 bg-gray-200 py-2 pl-6 pl-6 pr-2 border-l-4 border-blue-500 uppercase mt-4`}
`
export const Heading5 = styled.h5`
	${tw`font-display font-bold text-lg mb-6 text-blue-500 bg-gray-200 py-2 pl-6 pl-6 pr-2 border-l-4 border-blue-500 uppercase mt-4`}
`
export const Heading6 = styled.h6`
	${tw`font-display font-bold text-base mb-6 text-blue-500 bg-gray-200 py-2 pl-6 pl-6 pr-2 border-l-4 border-blue-500 uppercase mt-4`}
`
export const HyperLinkText = tw.a`font-display underline italic text-blue-500 hover:no-underline cursor-pointer`

export const NoContent = tw.p`font-display`
export const CrumbsContainer = styled.div`
	${tw`flex flex-row mb-3`}

	> a {
		${tw`font-display text-gray-900 font-bold hover:text-gray-500 mr-2`}
	}

	> svg {
		${tw`mr-2`}
	}
`
export const FaqTitle = tw.p`font-display text-gray-900 font-bold mr-2`
