import React from 'react'
import { HeroSection, Title } from './styles'

const Hero = ({ title }) => {
  return (
    <HeroSection>
      <Title>{title}</Title>
    </HeroSection>
  )
}

export default Hero
