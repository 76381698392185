import React from 'react'
import { graphql } from 'gatsby'
import filterLocale from '../components/utils/filter-locale'
import Layout from '../components/layout'
import Meta from '../components/meta'
import Hero from '../components/faq/hero'
import Content from '../components/faq/content'
import localeCheck from '../components/utils/locale-check'

const Faq = ({ pageContext: { locale, defaultLocale }, data: { faq } }) => {
  const [{ node }] = filterLocale(faq.edges, locale)
  const { title, keywords, description, slug, content } = node

  const checkedLocale = localeCheck(locale, defaultLocale)

  return (
    <Layout>
      <Meta
        title={title ? title : ''}
        keywords={keywords ? keywords : []}
        description={description ? description.description : ''}
        lang={locale}
      />
      <Hero {...{title}}/>
      <Content {...{checkedLocale, content, title}}/>
    </Layout>
  )
}

export const FaqPageQuery = graphql`
  query FaqPageBySlug($slug: String!) {
    faq: allContentfulFaq(filter: {slug: {eq: $slug}}) {
      edges {
        node {
          title
          description {
            description
          }
          keywords
          slug
          node_locale
          content {
            json
          }
        }
      }
    }
  }
`

export default Faq
